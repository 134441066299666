import React, { useContext } from "react"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/global-context-provider"

export const MainContent = ({ children }: any) => {
  return (
    <div className="min-w-full md:w-full lg:w-full sm:w-full">
      {children}
    </div>
  )
}

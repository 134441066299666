import React from "react"
import { MainHeader } from "../header/header"
import { MDXProvider } from "@mdx-js/react"
import { Footer } from "../footer/footer"
import { MainContent } from "../main-content/main-content"

import './layout.css';

const shortcodes = { MainHeader }

export default ({ children }: any) => {
  return (
    <MDXProvider components={shortcodes}>
      <div className="w-screen bg-no-repeat bg-cover bg-center overflow-x-hidden">
        <MainHeader />
        <MainContent>{children}</MainContent>
      </div>
    </MDXProvider >
  )
}

import React from "react"
import Typist from "react-typist"


const NavItems: any = [
  {
    value: `Home`,
    href: `/`,
    className: `m-4 hover:font-bold hover:text-black list-none hover:underline`,
  },
  {
    value: `Blog`,
    href: `/blog`,
    className: `m-4 hover:font-bold hover:text-black list-none hover:underline`,
  },
  {
    value: `Contact`,
    href: `/contact`,
    className: `m-4 hover:font-bold hover:text-black list-none hover:underline`,
  },
];

export const BlogHeader = () => {

  return (
    <ul className="flex p-4 justify-center">
      {NavItems.map((item: any, index: number) => {
        return (
          <a key={index} href={item.href}>
            <li key={index} className={item.className}>
              <Typist cursor={{ show: false }}>
                <Typist.Delay ms={300} />
                {item.value}
              </Typist>
            </li>
          </a>
        )
      })}
    </ul>
  )

}

export const MainHeader = () => {
  return (
    <div className="min-h-1/4 mb-4">
      <ul className="flex p-4 justify-center">
        {NavItems.map((item: any, index: number) => {
          return (
            <a key={index} href={item.href}>
              <li key={index} className={item.className}>
                <Typist cursor={{ show: false }}>
                  <Typist.Delay ms={300} />
                  {item.value}
                </Typist>
              </li>
            </a>
          )
        })}
      </ul>
    </div>
  )
}

